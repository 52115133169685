<template>
    <page-title
        title="ログイン"
        icon="bi-key"
    >
    </page-title>

    <section class="section">
        <form @submit.prevent="signin" class="col-md-8">
            <div class="mb-3">
                <label>ログインID</label>
                <input type="text" v-model="login_key" class="form-control" required="required">
            </div>
            <div class="mb-3">
                <label>パスワード</label>
                <input type="password" v-model="password" class="form-control" required="required">
            </div>
            <div class="mb-3">
                <button class="btn btn-primary btn-lg"><i class="bi bi-key"></i> ログイン</button>
            </div>
        </form>
    </section>

</template>

<script>
import PageTitle from '@/components/PageTitle';
// import axios from 'axios';

export default {
    components: {
        PageTitle
    },
    name: 'Signin',
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
    ],
    data() {
        return {
            login_key: null,
            password: null
        }
    },
    methods: {
        signin() {
            // this.startScreenLoading();
            // axios.post(process.env.VUE_APP_API_URI + '/signin', {
            //     login_key: this.login_key,
            //     password: this.password
            // })
            // .then((response) => {
            //     this.$store.commit('auth/setAccessToken', response.data);
            //     if (this.$route.query.redirect) {
            //         this.$router.push(this.$route.query.redirect);
            //     } else {
            //         if (this.$store.state.auth.is_provisional_password) {
            //             this.$router.push({name: 'Password'});

            //         } else {
            //             this.$router.push('/');
            //         }
            //     }
            // })
            // .catch((error) => {
            //     //※ここは $http 使っていないので、自力でメッセージ表示が必要
            //     if (error.response && error.response.status === 422) {
            //         this.showErrorMessage('メールアドレスかパスワードが違います');

            //     } else {
            //         this.showErrorMessage(error.response.data.message);
            //     }
            // })
            // .finally(() => {
            //     this.endScreenLoading();
            // });
        },
    }
}
</script>

<style scoped>

</style>
